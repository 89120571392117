<form>
    <div class="form-data">
        <ng-container>
            <app-form-generator
                *ngIf="emailContentFormGenInputs"
                [formGeneratorInput]="emailContentFormGenInputs"
                (formGroupRef)="emailContentFormGroup = $event"
            ></app-form-generator>
        </ng-container>
    </div>
    <div class="footer">
        <app-multi-button-generator
            [buttonGenInputs]="buttonGenInputs"
            [options]="buttonOptions"
        ></app-multi-button-generator>
    </div>
</form>

<div class="overlay" *ngIf="modalLoader">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>
