import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { Helper } from './../../../../classes/Helper';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';

@Component({
    selector: 'app-assessment-more-info-modal',
    templateUrl: './assessment-more-info-modal.component.html',
    styleUrls: ['./assessment-more-info-modal.component.sass']
})
export class AssessmentMoreInfoModalComponent implements OnInit {
    readonly assesseTableKeys = {
        companyName: 'Customer Name',
        userName: 'User Name',
        userEmailAddress: 'Email',
        userContactDetail: 'User Contact Number'
    };
    readonly assessmentTableKeys = {
        assessmentName: 'Name',
        assessmentIssued: 'Issued By',
        issueDate: 'Issued On',
        auditDueDate: 'Date of Assessment',
        assessmentStatus: 'Status',
        tags: 'Assessment Tags',
        jobId: 'Job Id',
        companyName: 'Issued To'
    };
    assesseeInfoTableGenInput: ITableGeneratorInput;
    assesseeInfoTableData: object[] = [];
    assessorInfoTableGenInput: ITableGeneratorInput;
    pmoInfoTableGenInput: ITableGeneratorInput;
    assesseUserInfoTableGenInput: ITableGeneratorInput;
    assesseResourceTagTableGenInput: ITableGeneratorInput;
    assessorInfoTableData: object[] = [];
    pmoInfoTableData: object[] = [];
    assesseUserInfoTableData: object[] = [];
    assesseResourceTagTableData: object[] = [];
    assessmentInfoTableGenInput: ITableGeneratorInput;
    assessmentInfoTableData: {
        key: string;
        value: string | number | string[];
    }[] = [];
    public tabsNameToTitleMap: { [key: string]: string } = {
        assessee: 'Assessee',
        assessmentInformation: 'Assessment Information',
        assessor: 'Assessor',
        pmo: 'Program Coordinator'
    };
    hideAssessorTab: boolean = false;
    hidePmoTab: boolean = false;
    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };
    consumptionInfoColDef = {
        sortable: false,
        filter: false,
        resizable: true,
        minWidth: 150,
        width: 200,
        suppressMovable: true,
        headerClass: 'grid-cell-data-centred'
    };
    widgetRef: Widget;
    isAssessee = false;
    constructor(public modalData: ModalInjectedData) {
        this.widgetRef = modalData.data['widgetRef'];
        if (modalData.data.assessee) {
            this.isAssessee = true;
        }
    }

    ngOnInit(): void {
        this.setUpTable();
        this.prepareTableData();
        if (
            this.modalData.data['showInfoData'] &&
            this.modalData.data['showInfoData']['moreInfo'] &&
            this.modalData.data['showInfoData']['moreInfo']['assessorInfo']
        ) {
            this.generateAssessorTable();
        } else {
            this.hideAssessorTab = true;
        }
        if (
            this.modalData.data['showInfoData'] &&
            this.modalData.data['showInfoData']['moreInfo'] &&
            this.modalData.data['showInfoData']['moreInfo']['pmoUserInfo']
        ) {
            this.generatePmoTable();
        } else {
            this.hidePmoTab = true;
        }
    }

    prepareTableData() {
        const assesseeInfoData: object[] =
            this.modalData.data['showInfoData']['moreInfo']['assesseUserInfo'];

        // Generate unique column keys
        const insertedColumnKeys: Set<string> = new Set(['key']);
        assesseeInfoData.map((data, index) => {
            insertedColumnKeys.add(`value${index}`);
        });

        // Generate columns
        const assesseUserInfoColumns: IColumnData[] = [];
        Array.from(insertedColumnKeys).map((key, index) => {
            const columnData: IColumnData = {
                columnKey: key,
                columnName: key
            };
            if (index === 0) {
                columnData.pinned = 'left';
                columnData.cellClass = 'pinned-key';
            }
            assesseUserInfoColumns.push(columnData);
        });

        // Table generator input
        this.assesseeInfoTableGenInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-this.chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            headerHeight: 0,
            tableAutoHeight: true,
            columns: assesseUserInfoColumns,
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };

        // Generate table data
        const tableData = [
            { key: 'User Name' },
            { key: 'User Email' },
            { key: 'User Contact Number' },
        ];
        assesseeInfoData.map((data, index) => {
            tableData[0][`value${index}`] = data['userName'];
            tableData[1][`value${index}`] = data['userEmailAddress'];
            tableData[2][`value${index}`] = data['userContactNumber']
                ? data['userContactNumber']
                : '-';
        });
        this.assesseeInfoTableData = tableData;

        const assessmentInfoData = this.modalData.data['showInfoData'];
        if (assessmentInfoData.auditDueDate !== '-') {
            assessmentInfoData.auditDueDate = assessmentInfoData.auditDueDate;
        } else {
            assessmentInfoData.auditDueDate = assessmentInfoData.auditDueDate;
        }
        if (
            this.modalData.data['showInfoData']['assessmentName'].includes(
                'CSP'
            ) &&
            this.modalData.data['showScores']
        ) {
            this.assessmentTableKeys['achievedScore'] = 'Acheived Score';
            this.assessmentTableKeys['selfAssessmentScore'] =
                'Self Assessment Score';
        }
        Object.keys(assessmentInfoData).forEach((key, index) => {
            if (
                typeof assessmentInfoData[key] !== 'object' &&
                !Array.isArray(assessmentInfoData[key]) &&
                assessmentInfoData[key] !== null &&
                this.assessmentTableKeys[key]
            ) {
                this.assessmentInfoTableData.push({
                    key: this.assessmentTableKeys[key],
                    value: assessmentInfoData[key]
                        ? assessmentInfoData[key]
                        : '-'
                });
            } else if (
                key === 'tags' &&
                assessmentInfoData[key] &&
                typeof assessmentInfoData[key] === 'object' &&
                assessmentInfoData[key].length
            ) {
                assessmentInfoData[key].forEach((keysub, i) => {
                    assessmentInfoData[key][i] = `<span style='color:${
                        keysub.colorCode ? keysub.colorCode : keysub?.color
                    }'>${keysub.name ? keysub.name : keysub?.label}</span>`;
                });
                this.assessmentInfoTableData.push({
                    key: this.assessmentTableKeys[key],
                    value: assessmentInfoData[key]
                });
            }
            if (
                this.modalData.data['showInfoData']['assessmentName'].includes(
                    'CSP'
                ) &&
                this.modalData.data['showScores']
            ) {
                if (
                    key === 'moreInfo' &&
                    assessmentInfoData[key] &&
                    typeof assessmentInfoData[key] === 'object'
                ) {
                    Object.keys(assessmentInfoData[key]).forEach(
                        (value, index) => {
                            if (this.assessmentTableKeys[value]) {
                                this.assessmentInfoTableData.push({
                                    key: this.assessmentTableKeys[value],
                                    value: assessmentInfoData[key][value]
                                        ? assessmentInfoData[key][value]
                                        : '-',
                                });
                            }
                        }
                    );
                }
            }
        });
    }

    setUpTable() {
        this.assesseeInfoTableGenInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-this.chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            headerHeight: 0,
            columns: [
                {
                    columnName: 'Root User Name',
                    columnKey: 'key',
                    cellClass: 'pinned-key'
                },
                {
                    columnName: 'User Email',
                    columnKey: 'value'
                }
            ],
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };

        this.assessmentInfoTableGenInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-this.chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            headerHeight: 0,
            tableAutoHeight: true,
            columns: [
                {
                    columnName: 'Key',
                    columnKey: 'key',
                    cellClass: 'pinned-key'
                },
                {
                    columnName: 'Value',
                    columnKey: 'value',
                    cellStyle: (data) => {
                        if (data.data && data.data['key'] === 'Status') {
                            return {
                                color:
                                    Helper.convertToHexIfHsl(
                                        Helper.getCssVarValue('accentColor')
                                    ) + ' !important'
                            };
                        }
                    },
                    cellRenderer: (rowData) => {
                        if (typeof rowData.value === 'object') {
                            return (rowData.value as Array<string>).join(',');
                        } else {
                            return rowData.data.value;
                        }
                    }
                }
            ],
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };
    }

    generateAssessorTable() {
        const assessorInfoData: object[] =
            this.modalData.data['showInfoData']['moreInfo']['assessorInfo'];

        // Generate unique column keys
        const insertedColumnKeys: Set<string> = new Set(['key','value0']);
        assessorInfoData.map((data, index) => {
            insertedColumnKeys.add(`value${index}`);
        });

        // Generate columns
        const assessorInfoColumns: IColumnData[] = [];
        Array.from(insertedColumnKeys).map((key, index) => {
            const columnData: IColumnData = {
                columnKey: key,
                columnName: key
            };
            if (index === 0) {
                columnData.pinned = 'left';
                columnData.cellClass = 'pinned-key';
            }
            assessorInfoColumns.push(columnData);
        });

        // Table generator input
        this.assessorInfoTableGenInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-this.chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            headerHeight: 0,
            tableAutoHeight: true,
            columns: assessorInfoColumns,
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };

        // Generate table data
        const tableData = [
            { key: 'Name', value0: '-' },
            { key: 'Email', value0: '-' },
        ];
        assessorInfoData.map((data, index) => {
            tableData[0][`value${index}`] = data['userName'];
            tableData[1][`value${index}`] = data['userEmailAddress'];
        });
        this.assessorInfoTableData = tableData;
    }
    generatePmoTable() {
        const pmoInfoData: object[] =
            this.modalData.data['showInfoData']['moreInfo']['pmoUserInfo'];

        // Generate unique column keys
        const insertedColumnKeys: Set<string> = new Set(['key']);
        pmoInfoData.map((data, index) => {
            insertedColumnKeys.add(`value${index}`);
        });

        // Generate columns
        const pmoInfoColumns: IColumnData[] = [];
        Array.from(insertedColumnKeys).map((key, index) => {
            const columnData: IColumnData = {
                columnKey: key,
                columnName: key
            };
            if (index === 0) {
                columnData.pinned = 'left';
                columnData.cellClass = 'pinned-key';
            }
            pmoInfoColumns.push(columnData);
        });

        // Table generator input
        this.pmoInfoTableGenInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-this.chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            headerHeight: 0,
            tableAutoHeight: true,
            columns: pmoInfoColumns,
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };

        // Generate table data
        const tableData = [{ key: 'Name' }, { key: 'Email' }];
        pmoInfoData.map((data, index) => {
            tableData[0][`value${index}`] = data['userName'];
            tableData[1][`value${index}`] = data['userEmailAddress'];
        });
        this.pmoInfoTableData = tableData;
    }
}
export interface IAssessmentData {
    assessmentId: string;
    companyName: string;
    userName: string;
    issueDate: Date;
    assessmentIssued: string;
    assessmentStatus: string;
    canAssign: boolean;
    canEvaluate: boolean;
    canDownloadReport: boolean;
    moreInfo: IMoreInfo;
}

export interface IMoreInfo {
    assesseInfo: IAssesseInfo;
    assessorInfo: IAssessorInfo[];
}

export interface IAssesseInfo {
    companyName: string;
    userName: string;
    userEmailAddress: string;
    userContactDetail: string;
    auditIssued: string;
    assessmentSubmitDate: string;
}

export interface IAssessorInfo {
    userName: string;
    userEmailAddress: string;
    userContactDetail: string;
}
